import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { ChevronLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import SettingInput from './SettingInput/SettingInput';
import ChangePin from './ChangePin/ChangePin';
import BlockCard from './BlockCard/BlockCard';
import VerificationModal from '../../Modals/VerificationModal/VerificationModal';

import './CardSettings.scss';

const CardSettings = ({ cardDetail }) => {
  const { t } = useTranslation();

  const [selectedSettingView, setSelectedSettingView] = useState(null);
  const [password, setPassword] = useState('');
  const [isShow2FaModal, setIsShow2FaModal] = useState('');
  const isDisabledPasswordButton = password?.length < 4;

  const handleOnChangePassword = res => {
    setPassword(res);
  };

  const handleOpenSettingViewBlock = title => {
    setSelectedSettingView(title);
  };

  const handleChangePassword = () => {
    console.log('password', password);
    console.log('password changed');
  };
  const handleBlockCard = () => {
    console.log('card is frozen');
  };

  const handlOpen2FaModal = () => {
    setIsShow2FaModal(true);
  };
  const handlClose2FaModal = () => {
    setIsShow2FaModal(false);
  };
  const handleResend = () => {
    console.log('resended');
  };

  const renderDefaultSettingsView = () => (
    <>
      <SettingInput title={t('myCard.settings.changePIN')} handleClick={() => handleOpenSettingViewBlock('pin')} />
      <SettingInput
        title={t('myCard.settings.blockCard')}
        handleClick={() => handleOpenSettingViewBlock('blockCard')}
      />
      <Checkbox className="card-setting__checkbox" onChange={e => console.log(e.target.checked)}>
        {t('myCard.settings.makeDefault')}
      </Checkbox>
    </>
  );

  const renderSettingContentView = () => {
    if (selectedSettingView === 'pin') {
      return <ChangePin handleOnChangePassword={handleOnChangePassword} handleChangePassword={handlOpen2FaModal} />;
    }
    if (selectedSettingView === 'blockCard') {
      return <BlockCard cardDetail={cardDetail} handleBlockCard={handleBlockCard} />;
    }
    return renderDefaultSettingsView();
  };

  const textForSubtitle = `${t('myCard.verificationModal.subtitle')} (307) 555-0133`;
  const textForTitle = `${t('myCard.verificationModal.title')}`;
  return (
    <div className="card-setting">
      {selectedSettingView && (
        <ChevronLeft
          className="card-setting__back"
          onClick={() => handleOpenSettingViewBlock(null)}
          size={24}
          color="#9B9FB8"
          cursor="pointer"
        />
      )}
      {renderSettingContentView()}
      <VerificationModal
        title={textForTitle}
        isVisible={isShow2FaModal}
        closeModal={handlClose2FaModal}
        handleSubmit={handleChangePassword}
        isTwoFa={true}
        subtitle={textForSubtitle}
        lengthForInput={4}
        handleResend={handleResend}
      />
    </div>
  );
};

export default CardSettings;
