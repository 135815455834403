import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import CreateNewCard from '../CreateNewCard/CreateNewCard';
import CardDetails from '../CardDetails/CardDetails';
import PersonalCard from '../PersonalCard/PersonalCard';
import CardSettings from '../CardSettings/CardSettings';
import { ReactComponent as Plus } from '../../../img/add-square.svg';
import { ReactComponent as Detail } from '../../../img/document.svg';
import { ReactComponent as SettingsIcon } from '../../../img/settings.svg';

import './ViewMainInfoMyCards.scss';

const ViewMainInfoMyCards = ({ handleOpenBanner }) => {
  const { t } = useTranslation();

  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [selectedView, setSelectedView] = useState(null);
  const [cards, setCards] = useState([
    {
      id: '1',
      name: 'Virtual Green',
      lastFourDigits: '3456',
      expirationDate: '01/26',
      number: '1234 5678 9012 3456',
      cardholderName: 'Wade Warren',
      cvv2: '777',
      billingAddress: '775 Rolling Green Rd.',
      monthlyPayment: '9.99',
      balance: 63822.5,
      isBlocked: false,
    },
    {
      id: '2',
      name: 'Virtual Yellow',
      lastFourDigits: '4444',
      expirationDate: '03/27',
      number: '7777 5678 9012 4444',
      cardholderName: 'Dart Waider',
      cvv2: '111',
      billingAddress: '7 Rolling Green Rd.',
      monthlyPayment: '19.99',
      balance: 622.7,
      isBlocked: true,
    },
  ]);

  const nextCard = () => {
    setCurrentCardIndex(prevIndex => (prevIndex + 1) % (cards.length + 1));
  };

  const prevCard = () => {
    setCurrentCardIndex(prevIndex => (prevIndex - 1 + cards.length + 1) % (cards.length + 1));
  };
  const handleCreate = () => {
    console.log('Created new card');
  };

  const calculateTransform = () => {
    return `translateX(calc(-${currentCardIndex * 90}% - ${currentCardIndex * 10}px + ${currentCardIndex * 110}px))`;
  };

  const selectedCard = currentCardIndex < cards.length ? cards[currentCardIndex] : null;

  const renderBottomContent = () => {
    if (!selectedView) return null;

    return (
      <div className="view-my-card-card-container__bottom-wrapper">
        {selectedView === 'details' && selectedCard ? (
          <CardDetails card={selectedCard} />
        ) : selectedView === 'settings' ? (
          <CardSettings cardDetail={selectedCard} />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const handleOpenViewBlock = (isOpenBanner = false, title) => {
    handleOpenBanner(isOpenBanner);
    setSelectedView(title);
  };

  const getTitleText = title => {
    switch (title) {
      case 'details':
        return t('myCard.details.title');

      case 'settings':
        return t('myCard.settings.title');

      default:
        return t('myCard.title');
    }
  };

  return (
    <div className="view-my-card-card-container">
      <div className="view-my-card-card-container__top-wrapper">
        <div className="view-my-card-card-container__header">
          <h2 className="view-my-card-card-container__header-title">{getTitleText(selectedView)}</h2>
          {selectedView ? (
            <X onClick={() => handleOpenViewBlock(true, null)} size={24} color="#4B5563" cursor="pointer" />
          ) : (
            <div className="view-my-card-card-container__header-nav-buttons">
              <ChevronLeft
                className="view-my-card-card-container__header-nav-button view-my-card-card-container__header-nav-button"
                onClick={prevCard}
                size={24}
                color="#4B5563"
              />

              <ChevronRight
                className="view-my-card-card-container__header-nav-button"
                onClick={nextCard}
                size={24}
                color="#4B5563"
              />
            </div>
          )}
        </div>
        <div className="view-my-card-card-container__card-wrapper">
          {!selectedView ? (
            <div
              className="view-my-card-card-container__card-wrapper-card-slider"
              style={{
                transform: calculateTransform(),
              }}
            >
              {cards.map((card, index) => (
                <PersonalCard isBlocked={card?.isBlocked} card={card} />
              ))}
              <CreateNewCard handleCreate={handleCreate} />
            </div>
          ) : (
            <PersonalCard isRotated={selectedView !== null} card={selectedCard} />
          )}
        </div>
        <div className="view-my-card-card-container__detailed-wrapper"></div>

        {selectedCard && (
          <>
            <div className="view-my-card-card-container__balance-section">
              <span className="view-my-card-card-container__balance-section-label">
                {t('myCard.viewCard.availableBalance')}
              </span>
              <span className="view-my-card-card-container__balance-section-amount">
                ${cards[currentCardIndex]?.balance.toFixed(2)}
              </span>
            </div>
            {!selectedView && (
              <div className="view-my-card-card-container__action-buttons">
                <button
                  disabled={selectedCard.isBlocked}
                  className={`view-my-card-card-container__action-buttons-button ${
                    selectedCard.isBlocked ? 'disabled-button' : ''
                  }`}
                >
                  <Plus className="view-my-card-card-container__action-buttons-icon" />
                  {t('myCard.viewCard.topUp')}
                </button>
                <button
                  disabled={selectedCard.isBlocked}
                  onClick={() => handleOpenViewBlock(false, 'details')}
                  className={`view-my-card-card-container__action-buttons-button ${
                    selectedCard.isBlocked ? 'disabled-button' : ''
                  }`}
                >
                  <Detail className="view-my-card-card-container__action-buttons-icon" />
                  {t('myCard.viewCard.details')}
                </button>
                <button
                  onClick={() => handleOpenViewBlock(false, 'settings')}
                  className="view-my-card-card-container__action-buttons-button"
                >
                  <SettingsIcon
                    width={24}
                    height={24}
                    stroke="white"
                    className="view-my-card-card-container__action-buttons-icon"
                  />
                  {t('myCard.viewCard.settings')}
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {renderBottomContent()}
    </div>
  );
};

export default ViewMainInfoMyCards;
