import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CardLogo } from '../../../img/debitCard/masterCardLogo.svg';
import { ReactComponent as CvvLogo } from '../../../img/debitCard/cvv.svg';

import './CardDetails.scss';

const CardDetails = ({ card }) => {
  const { t } = useTranslation();
  const [showCvvCode, setShowCvvCode] = useState(false);

  return (
    <div key={card?.id} className="card">
      <span className="card__title">{card?.name}</span>

      <div className="card__body">
        <div className="card__body-item">
          <span>{t('myCard.details.paymentNetwork')}</span>

          <CardLogo />
        </div>
        <div className="card__body-item">
          <span>{t('myCard.details.cardNumber')}</span>
          <span className="card__body-item-subtitle">{card?.number}</span>
        </div>
        <div className="card__body-item">
          <span>{t('myCard.details.expirationDate')}</span>
          <span className="card__body-item-subtitle">{card?.expirationDate}</span>
        </div>
        <div className="card__body-item">
          <span>{t('myCard.details.cardholderName')}</span>
          <span className="card__body-item-subtitle">{card?.cardholderName}</span>
        </div>
        <div className="card__body-item">
          <span>{t('myCard.details.cvv2')}</span>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setShowCvvCode(!showCvvCode)}
            className="card__body-item-subtitle"
          >
            {showCvvCode ? `${card?.cvv2}` : <CvvLogo />}
          </span>
        </div>
        <div className="card__body-item">
          <span>{t('myCard.details.billingAddress')}</span>
          <span className="card__body-item-subtitle">{card?.billingAddress}</span>
        </div>
        <div className="card__body-item">
          <span>{t('myCard.details.monthlyPayment')}</span>
          <span className="card__body-item-subtitle">
            ${card?.monthlyPayment} {t('myCard.details.monthPeriod')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
