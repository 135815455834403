import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BackIcon } from '../../../../img/back-button.svg';

import useWindowSize from '../../../../utils/resizeHook';

import './DetailedTransaction.scss';

const DetailedTransaction = ({ transaction, handleCloseDetailedTransaction }) => {
  const { t } = useTranslation();

  return (
    <div className="detailed-transaction">
      <div className="detailed-transaction__header">
        <BackIcon onClick={handleCloseDetailedTransaction} className="detailed-transaction__back" />
        <div className="detailed-transaction__header-info">
          <div className="detailed-transaction__header-info-logo">{transaction?.logo}</div>
          <div
            className={`detailed-transaction__header-info-amount detailed-transaction__header-info-amount_${transaction.status.toLowerCase()}`}
          >
            {transaction?.amount}
          </div>
          <div
            className={`detailed-transaction__header-info-type detailed-transaction__header-info-type_${transaction?.status}`}
          >
            {transaction?.status}
          </div>
        </div>
      </div>
      <div className="detailed-transaction__body">
        <div className="detailed-transaction__body-item">
          <span>
            {t(moment(transaction?.createdAt).format('MMM').toLowerCase())}{' '}
            {moment(transaction?.createdAt).format('D, YYYY, HH:mm;')}
          </span>
          <span className="detailed-transaction__body-item-subtitle">{t('myCard.transactions.date')}</span>
        </div>
        <div className="detailed-transaction__body-item">
          <span>{transaction?.balance}</span>
          <span className="detailed-transaction__body-item-subtitle">{t('myCard.transactions.balance')}</span>
        </div>
        <div className="detailed-transaction__body-item">
          <span>{transaction?.category}</span>
          <span className="detailed-transaction__body-item-subtitle">{t('myCard.transactions.category')}</span>
        </div>

        <div className="detailed-transaction__body-buttons">
          <button className="detailed-transaction__body-buttons-button">{t('myCard.transactions.getReceipt')}</button>
          <button
            style={{ background: '#4D7F70', color: 'white' }}
            className="detailed-transaction__body-buttons-button"
          >
            {t('myCard.transactions.repeatTransfer')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailedTransaction;
