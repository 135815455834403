import React, { useState } from 'react';
// import { connect } from 'react-redux';
// import useWindowSize from '../../../utils/resizeHook';
// import WrapperViewMainInfoMyCards from '../WrapperViewMainInfoMyCards/WrapperViewMainInfoMyCards';
import ViewMainInfoMyCards from '../ViewMainInfoMyCards/ViewMainInfoMyCards';
import BannerForMyCards from '../BannerForMyCards/BannerForMyCards';
import './ViewMyCardsBlock.scss';

const ViewMyCardsBlock = ({}) => {
  const [isShowBanner, setIsShowBanner] = useState(true);

  const handleClose = () => {
    setIsShowBanner(false);
  };

  const handleOrder = () => {
    console.log('Order');
  };

  return (
    <div className="view-my-card-wrapper">
      <ViewMainInfoMyCards handleOpenBanner={setIsShowBanner} />
      {isShowBanner && <BannerForMyCards handleClose={handleClose} handleOrder={handleOrder} />}
    </div>
  );
};

export default ViewMyCardsBlock;
