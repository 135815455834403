import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import './BannerForMyCards.scss';

const BannerForMyCards = ({ handleClose, handleOrder }) => {
  const { t } = useTranslation();

  return (
    <div className="banner-modal">
      <X onClick={handleClose} color="#5F6E80" size={20} className="banner-modal__close-icon" />
      <div className="banner-modal__body">
        <div className="banner-modal__title">{t('myCard.banner.title')}</div>
        <div className="banner-modal__text">{t('myCard.banner.text')}</div>
        <Button onClick={handleOrder} className="banner-modal__button">
          {t('myCard.banner.button')}
        </Button>
      </div>
    </div>
  );
};

export default BannerForMyCards;
