import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';

import './SettingInput.scss';

const SettingInput = ({ title, handleClick }) => {
  return (
    <div onClick={handleClick} className="setting-input">
      <span>{title}</span>
      <ChevronRight />
    </div>
  );
};

export default SettingInput;
