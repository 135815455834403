import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Form, Input, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import AuthCode from 'react-auth-code-input';
import Button from '../../Button';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import ResendCode from '../../ResendCode/ResendCode';
// import '../../../layout/Modal.scss';
import './VerificationModal.scss';

const VerificationModal = ({
  title,
  isVisible,
  closeModal,
  isTwoFa,
  isFetching,
  handleSubmit,
  notificationsErrorShow,
  subtitle,
  lengthForInput = 6,
  handleResend,
}) => {
  const { t } = useTranslation();
  const [verificationForm] = Form.useForm();

  useEffect(() => {
    if (!isVisible && !isTwoFa) {
      verificationForm.resetFields();
    }
  }, [isVisible, isTwoFa]);

  return (
    <Modal
      // title={title}
      visible={isVisible}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
      className="verification-modal"
    >
      <div className="verification-modal__wrapper">
        {isFetching && !isTwoFa && (
          <div className="modal__spinner">
            <Spin />
          </div>
        )}
        <Form
          form={verificationForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <span className="verification-modal__title">{title}</span>
          <>
            <Form.Item
              label={subtitle}
              rules={[{ required: true, message: t('validation.required') }]}
              name="valueTwoFa"
              className="verification-modal__item"
            >
              <AuthCode
                autoFocus={true}
                length={lengthForInput}
                containerClassName="verification-modal__item__contaiter"
                inputClassName="verification-modal__item__input"
              />
            </Form.Item>
            <ResendCode handleResend={handleResend} />
          </>
        </Form>

        <Button
          className="verification-modal__button"
          loading={isFetching && isTwoFa}
          onClick={() => verificationForm.submit()}
        >
          {t('myCard.verificationModal.verify')}
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationModal);
