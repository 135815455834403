import React from 'react';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import './CreateNewCard.scss';

const CreateNewCard = ({ handleCreate }) => {
  const { t } = useTranslation();

  return (
    <div className="add-card">
      <div className="add-card__content" onClick={handleCreate}>
        <div className="add-card__icon-circle">
          <Plus size={20} color="#3B4857" />
        </div>
        <span className="add-card__text">{t('myCard.viewCard.addNewCard')}</span>
      </div>
    </div>
  );
};

export default CreateNewCard;
