import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import useWindowSize from '../../../../utils/resizeHook';

import './CardTransaction.scss';

const CardTransaction = ({ transaction, handleOpenDetailedTransaction }) => {
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  return (
    <>
      <div className="card-transaction" onClick={() => handleOpenDetailedTransaction(transaction)}>
        <Row align="middle">
          <Col span={currentWidth >= 1024 ? 1 : 2}>
            <div className="card-transaction__logo">
              {transaction?.logo}
              {/* <TokenIcon tokenName={transaction?.info?.currency} /> */}
            </div>
          </Col>
          <Col span={8}>
            <div className="card-transaction__name">
              <span className="card-transaction__name-type">{transaction?.type}</span>
              <span>{transaction?.name}</span>
            </div>
          </Col>

          <Col span={currentWidth >= 1024 ? 15 : 14}>
            <div className="card-transaction__detailed">
              <span
                className={`card-transaction__detailed-amount card-transaction__detailed-amount_${transaction.status.toLowerCase()}`}
              >
                {transaction?.amount} $
              </span>
              <span className="card-transaction__detailed-date">
                {t(moment(transaction?.createdAt).format('MMM').toLowerCase())}{' '}
                {moment(transaction?.createdAt).format('D, YYYY, HH:mm;')}
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CardTransaction;
