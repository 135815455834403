import React, { useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Menu, Tooltip, Dropdown } from 'antd';
import { showAppTour } from '../redux/actions/user';
import HeaderUser from '../components/HeaderUser/HeaderUser';
import MerchantSelector from '../components/MerchantSelector/MerchantSelector';
import Button from '../components/Button';
import { ReactComponent as HomeIconSVG } from '../img/navigation/home.svg';
import { ReactComponent as MerchantsIconSVG } from '../img/navigation/merchants.svg';
import { ReactComponent as WalletsIconSVG } from '../img/navigation/wallet.svg';
// import { ReactComponent as CheckoutsIconSVG } from '../img/navigation/checkoutsIcon.svg';
import { ReactComponent as WithdrawIconSVG } from '../img/navigation/withdraw.svg';
// import { ReactComponent as SettingsIconSVG } from '../img/navigation/settings.svg';
import { ReactComponent as ProfileIconSVG } from '../img/profileCircle.svg';
import { ReactComponent as CpayCardSVG } from '../img/navigation/cpayCard.svg';
import { ReactComponent as SwapSVG } from '../img/navigation/swap.svg';
import { ReactComponent as MyCardsSVG } from '../img/credit-card.svg';
// import { ReactComponent as RepeatGuideSVG } from '../img/navigation/cpayTourSidebar.svg';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../utils/resizeHook';
import TransakOpen from '../utils/transakSDK';
import './Sider.scss';
import OrderCard from '../components/OrderCard/OrderCard';
import appStore from '../img/adminPanel/app-store.svg';
import googlePlay from '../img/adminPanel/google-play.svg';
import appStoreMin from '../img/adminPanel/app-store-min.svg';
import googlePlayMin from '../img/adminPanel/play-market-min.svg';

function Sider({ siderState, userRole, setShowAppTour, userData }) {
  const { isSm, currentWidth } = useWindowSize();
  const [showCardModal, setShowCardModal] = useState(false);
  const { t, i18n } = useTranslation();
  const walletsMenu = (
    <Menu>
      <Menu.Item>
        <NavLink exact to={`/wallets`}>
          {t('merchants.merchantWallets')}
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink exact to={`/clients`}>
          {t('merchants.clientWallets')}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const Links = [
    {
      path: '/',
      text: t('home'),
      svg: <HomeIconSVG />,
      className: 'side-bar__home',
      id: 0,
    },
    {
      path: '/wallets',
      text: t('wallets.wallets'),
      svg: <WalletsIconSVG />,
      className: 'side-bar__wallets',
      id: 2,
      // overlay: walletsMenu,
    },
    // {
    //   path: '/checkouts',
    //   text: t('checkouts.checkouts'),
    //   svg: <CheckoutsIconSVG />,
    //   className: 'side-bar__checkouts',
    //   id: 3,
    // },
    {
      path: '/withdraw',
      text: t('withdraw.withdraw'),
      svg: <WithdrawIconSVG />,
      className: 'side-bar__withdraw',
      id: 4,
    },
    {
      path: '/swap',
      text: t('sideBar.swap'),
      svg: <SwapSVG />,
      className: 'side-bar__withdraw',
      id: 5,
    },
    {
      path: '/multiwallet',
      text: t('merchants.multiwallet'),
      svg: <MerchantsIconSVG />,
      className: 'side-bar__merchants',
      id: 6,
    },
    {
      path: '/my-cards',
      text: t('myCards'),
      svg: <MyCardsSVG />,
      className: 'side-bar__withdraw',
      id: 7,
    },
    // {
    //   path: '/settings',
    //   text: t('settings'),
    //   svg: <SettingsIconSVG />,
    //   className: 'side-bar__settings',
    //   id: 6,
    // },
  ];

  const token = userData ? localStorage.getItem('authToken') : '';

  // if (userRole === 'corporate' && !Links.find(link => link.path === '/merchants')) {
  //   Links.splice(1, 0, {
  //     path: '/merchants',
  //     text: t('merchants.merchants'),
  //     svg: <MerchantsIconSVG />,
  //     className: 'side-bar__merchants',
  //     id: 1,
  //   });
  // }

  if (currentWidth < 1024) {
    if (!Links.find(link => link.path === '/profile')) {
      Links.unshift({
        path: '/profile',
        text: t('profile.profile'),
        svg: <ProfileIconSVG />,
        className: 'side-bar__profile',
        id: 10,
      });
    }
  }

  const AltLinks = [
    // {
    //   path: '',
    //   text: 'API Documentention',
    //   id: 6,
    // },
    // {
    //   path: '',
    //   text: 'Help',
    //   id: 7,
    // },
    // {
    //   path: '',
    //   text: 'Contact us',
    //   id: 8,
    // },
  ];

  return (
    <>
      <div className={cn('side-bar', { ['side-bar__closed']: !siderState })}>
        <div className="side-bar__wrapper">
          <div className="side-bar__block">
            {Links.map(link =>
              userRole === 'corporate' && link.overlay ? (
                <Dropdown overlay={link.overlay} placement="bottomLeft" arrow>
                  <div className="side-bar__nav-link" key={link.path}>
                    <NavLink
                      exact={link.path !== '/swap'}
                      className={`side-bar__link ${link.className}`}
                      activeClassName="side-bar__link_active"
                      to={link.path}
                    >
                      {link.svg}
                      <span>{link.text}</span>
                    </NavLink>
                  </div>
                </Dropdown>
              ) : !siderState ? (
                <Tooltip title={link.text} placement="right" overlayClassName={`sider__nav-tooltip_${siderState}`}>
                  <div className="side-bar__nav-link" key={link.id}>
                    <NavLink
                      exact={link.path !== '/swap'}
                      className={`side-bar__link ${link.className}`}
                      activeClassName="side-bar__link_active"
                      to={link.path}
                    >
                      {link.svg}
                      <span>{link.text}</span>
                    </NavLink>
                  </div>
                </Tooltip>
              ) : (
                <div className="side-bar__nav-link" key={link.id}>
                  <NavLink
                    exact={link.path !== '/swap'}
                    className={`side-bar__link ${link.className}`}
                    activeClassName="side-bar__link_active"
                    to={link.path}
                  >
                    {link.svg}
                    <span>{link.text}</span>
                  </NavLink>
                </div>
              )
            )}

            <div className="side-bar__nav-btn">
              <button onClick={TransakOpen} className={cn('side-bar__buy', { ['side-bar__closed']: !siderState })}>
                {currentWidth > 1359 && !siderState ? t('buy') : t('buyCripto')}
              </button>

              <button
                onClick={() => setShowCardModal(true)}
                className={cn('side-bar__card', { ['side-bar__closed']: !siderState })}
              >
                {!siderState ? 'Card' : t('orderCard.order')}
              </button>
            </div>

            {/* {currentWidth >= 1024 && (
            <>
              {!siderState ? (
                <Tooltip
                  title={t('sideBar.repeatGuide')}
                  placement="right"
                  overlayClassName={`sider__nav-tooltip_${siderState}`}
                >
                  <div className="side-bar__nav-link">
                    <div className="side-bar__link side-bar__guide" onClick={() => setShowAppTour(true)}>
                      <RepeatGuideSVG />
                      <span>{t('sideBar.repeatGuide')}</span>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <div className="side-bar__nav-link">
                  <div className="side-bar__link side-bar__guide" onClick={() => setShowAppTour(true)}>
                    <RepeatGuideSVG />
                    <span>{t('sideBar.repeatGuide')}</span>
                  </div>
                </div>
              )}
            </>
          )} */}

            {/* {siderState ? (
            <div className={cn('side-bar__card-preorder', { es: i18n.language === 'es' })}>
              <a
                href={
                  token
                    ? `${process.env.REACT_APP_DEMO_CARD_API_URL}?token=${token}&lang=${
                        i18n.language !== 'en' ? i18n.language : undefined
                      }`
                    : `${process.env.REACT_APP_DEMO_CARD_API_URL}`
                }
                target="_blank"
              >
                <Button type="secondary">{t('sideBar.debitCard')}</Button>
              </a>
            </div>
          ) : (
            <div className="side-bar__card-preorder_icon">
              <a
                href={
                  token
                    ? `${process.env.REACT_APP_DEMO_CARD_API_URL}?token=${token}&lang=${
                        i18n.language !== 'en' ? i18n.language : undefined
                      }`
                    : `${process.env.REACT_APP_DEMO_CARD_API_URL}`
                }
                target="_blank"
              >
                <CpayCardSVG />
              </a>
            </div>
          )} */}

            <div className="side-bar__controls">
              <MerchantSelector />
              <HeaderUser />
            </div>
          </div>
          <div className={!siderState && 'side-bar__hide'}>
            <p className="side-bar__mobile">Mobile App</p>
            <a
              className="side-bar__button"
              href="https://apps.apple.com/lt/app/yomi-wallet/id6450423330"
              target="_blank"
            >
              {currentWidth > 1359 && !siderState ? (
                <img src={appStoreMin} alt="app-store-min" />
              ) : (
                <img src={appStore} alt="app-store" />
              )}
            </a>
            <a
              className="side-bar__button side-bar__button-top"
              href="https://play.google.com/store/apps/details?id=com.app.yomi.finance"
              target="_blank"
            >
              {currentWidth > 1359 && !siderState ? (
                <img src={googlePlayMin} alt="google-play-min" />
              ) : (
                <img src={googlePlay} alt="google-play" />
              )}
            </a>
          </div>
        </div>

        <div className="side-bar__block">
          {AltLinks.map(link => (
            <div key={link.id}>{link.text}</div>
          ))}
        </div>
      </div>

      <OrderCard userData={userData} showCardModal={showCardModal} setShowCardModal={setShowCardModal} />
    </>
  );
}

const mapStateToProps = state => ({
  userRole: state.user.data.role,
  userData: state.user.data,
});

const mapDispatchToProps = dispatch => ({
  setShowAppTour: data => dispatch(showAppTour(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sider);
