import React from 'react';
import { Search, Filter } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import CardTransaction from '../Transaction/CardTransaction';
import './TransactionList.scss';

const TransactionList = ({ transactionInfo, handleOpenDetailedTransaction }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="transaction-list">
        <div className="transaction-list__header">
          <div className="transaction-list__header-title">{t('myCard.transactions.title')}</div>
          <div className="transaction-list__header-filters">
            <div className="transaction-list__header-filters-filter">
              <Search color="#3B4857" size={16} />
            </div>
            <div className="transaction-list__header-filters-filter">
              <Filter color="#3B4857" size={16} />
            </div>
          </div>
        </div>
        <div className="transaction-list__list">
          {transactionInfo?.length > 0 &&
            transactionInfo?.map(transaction => (
              <CardTransaction
                transaction={transaction}
                key={transaction?.id}
                handleOpenDetailedTransaction={handleOpenDetailedTransaction}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default TransactionList;
