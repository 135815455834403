import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { resendEmailTwoFa } from '../../redux/actions/twoFactor';
import { Timer } from '../OrderCard/Timer';
import { addMinutes } from '../../utils/addMinutes';
import './ResendCode.scss';

const ResendCode = ({ handleResend }) => {
  const [disableResend, setDisableResend] = useState(true);
  const { t } = useTranslation();

  const validUntil = addMinutes(1);

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
      setDisableResend(false);
    }, 60000);

    return () => clearTimeout(timeout);
  };

  return (
    <div className="resend-code">
      <span>{t('myCard.verificationModal.dontReceive')}</span>
      {disableResend ? (
        <span className="resend-code__timer">
          {' '}
          Receive in <Timer validUntil={validUntil} onExpire={() => setDisableResend(false)} />
        </span>
      ) : (
        <span className={`resend-code__button `} onClick={handleResend}>
          {' '}
          {t('myCard.verificationModal.resend')}
        </span>
      )}
    </div>
  );
};

export default ResendCode;
