import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CardLogo } from '../../../img/debitCard/masterCardLogo.svg';
import { ReactComponent as Snowflake } from '../../../img/debitCard/snowflake.svg';
import './PersonalCard.scss';

const PersonalCard = ({ card, isRotated, isBlocked }) => {
  const { t } = useTranslation();

  return (
    <div key={card.id} className={`credit-card ${isRotated ? 'credit-card--rotated' : ''}`}>
      <div className="credit-card__header">
        <span className="credit-card__name">{card?.name}</span>
        <CardLogo />
      </div>
      <div className="credit-card__footer">
        <div className="credit-card__footer-number">**** {card?.lastFourDigits}</div>
        <div className="credit-card__footer-expiry">{card?.expirationDate}</div>
      </div>
      {isBlocked && (
        <div className="credit-card__overlay">
          <Snowflake className="credit-card__overlay-icon" />
          <div className="credit-card__overlay-text">{t('myCard.viewCard.frozenCard')}</div>
        </div>
      )}
    </div>
  );
};

export default PersonalCard;
