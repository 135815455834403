import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../Button';
import './BlockCard.scss';

const BlockCard = ({ cardDetail, handleBlockCard }) => {
  const { t } = useTranslation();

  return (
    <div className="block-card">
      <span className="block-card__title">{t('myCard.settings.blockCard')}</span>
      <span className="block-card__subtitle">{t('myCard.settings.blockCardText')}</span>
      <Button onClick={handleBlockCard} className={'block-card__button'}>
        {cardDetail?.isBlocked ? t('myCard.settings.unblock') : t('myCard.settings.block')}
      </Button>
    </div>
  );
};

export default BlockCard;
