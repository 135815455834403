import React, { useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import './ChangePin.scss';

const ChangePin = ({ handleChangePassword, handleOnChangePassword }) => {
  const { t } = useTranslation();

  return (
    <div className="change-pin">
      <span className="change-pin__title">{t('myCard.settings.changePIN')}</span>
      <span className="change-pin__subtitle">{t('myCard.settings.newPIN')}</span>
      <div className="change-pin__input-block">
        <AuthCode
          autoFocus={false}
          length={4}
          allowedCharacters="numeric"
          isPassword="true"
          containerClassName="pincode-container"
          inputClassName="pincode-input"
          onChange={handleOnChangePassword}
        />
        <ArrowRight
          onClick={handleChangePassword}
          size={40}
          color="#2988EF"
          className="change-pin__input-block__action-arrow"
        />
      </div>
    </div>
  );
};

export default ChangePin;
