import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Utensils, Hotel, PiggyBank } from 'lucide-react';
import ViewMyCardsBlock from './ViewMyCardsBlock/ViewMyCardsBlock';
import TransactionList from './Transactions/TransactionsList/TransactionsList';
import DetailedTransaction from './Transactions/DetailedTransaction/DetailedTransaction';
import './MyCardsComponent.scss';

const transactionInfo = [
  {
    id: 1,
    name: 'Hotel',
    type: 'Restaurant',
    amount: '-300.00',
    createdAt: '2024-04-17T09:47:44.812Z',
    logo: <Hotel size={24} color="blue" />,
    balance: '300',
    currency: 'USD',
    category: 'Hotel payment',
    status: 'sent',
  },
  {
    id: 2,
    name: "McDonald's",
    type: 'Restaurant',
    amount: '-200.00',
    createdAt: '2024-04-17T09:47:44.812Z',
    logo: <Utensils size={24} color="orange" />,
    balance: '800',
    currency: 'USD',
    category: 'Food payment',
    status: 'sent',
  },
  {
    id: 3,
    name: 'KFC',
    type: 'Restaurant',
    amount: '-200.00',
    createdAt: '2024-04-17T09:47:44.812Z',
    logo: <Utensils size={24} color="orange" />,
    balance: '800',
    currency: 'USD',
    category: 'Transfer to the card',
    status: 'sent',
  },
  {
    id: 4,
    name: 'Send Money',
    type: 'Transfer',
    amount: '+700.00',
    createdAt: '2024-04-17T09:47:44.812Z',
    logo: <PiggyBank size={24} color="green" />,
    balance: '2800',
    currency: 'USD',
    category: 'Transfer to the card',
    status: 'get',
  },
  {
    id: 5,
    name: "McDonald's",
    type: 'Restaurant',
    amount: '×200.00',
    createdAt: '2024-04-17T09:47:44.812Z',
    logo: <Utensils size={24} color="orange" />,
    balance: '800',
    currency: 'USD',
    category: 'Transfer to the card',
    status: 'error',
  },
  {
    id: 6,
    name: "McDonald's",
    type: 'Restaurant',
    amount: '-200.00',
    createdAt: '2024-04-17T16:47:44.812Z',
    logo: <Utensils size={24} color="orange" />,
    balance: '800',
    currency: 'USD',
    category: 'Transfer to the card',
    status: 'sent',
  },
];

const MyCardsComponent = ({}) => {
  const [isOpenDetaildTransaction, setIsOpenDetaildTransaction] = useState(false);
  const [detailedData, setDetailedData] = useState({});

  const handleOpenDetailedTransaction = data => {
    setDetailedData(data);
    setIsOpenDetaildTransaction(true);
  };

  const handleCloseDetailedTransaction = () => {
    setIsOpenDetaildTransaction(false);
  };

  return (
    <div className="my-cards-page">
      <div className="my-cards-page__left">
        <ViewMyCardsBlock />
      </div>
      {!isOpenDetaildTransaction ? (
        <TransactionList
          transactionInfo={transactionInfo}
          handleOpenDetailedTransaction={handleOpenDetailedTransaction}
        />
      ) : (
        <DetailedTransaction
          transaction={detailedData}
          handleCloseDetailedTransaction={handleCloseDetailedTransaction}
        />
      )}

      {/* <div className="my-cards-page__right">Right</div> */}
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyCardsComponent);
