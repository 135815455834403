import React from 'react';

import MyCardsComponent from '../components/MyCards/MyCardsComponent';

const MyCards = () => {
  return (
    <>
      <MyCardsComponent />
    </>
  );
};

export default MyCards;
